<template>
    <div class="M4">
        <div>
            <div class="M4-tip-title">M1-M3模型评分差值说明</div>
            <div class="M4-score">
                <div class="M4-score-textarea">
                    <textarea name="" id="" cols="30" rows="10" v-model="form.text"></textarea>
                </div> 
                <div class="M4-score-score">
                    <img src="../../../assets/images/credit/credit-Model/组 53@2x.png"/>
                    <div class="title">最终标准信用评分</div>
                    <div class="Num">{{parseInt(anum)}}</div> 
                </div>
            </div>

            <div class="credit-title">
                <div>
                <div class="iconImg">
                    <img src="../../../assets/images/credit/credit-Model/经济@2x.png">
                </div>
                    <span>减分调整项</span>
                </div>
                <div>
                    <div class="credit-title-todetail" ></div>
                </div>
            </div>

            <div class="tableBox">
                <table class="re_search_detail_table">
                    <thead>
                    <tr>
                        <td width="70%;" colspan="2" class="detailtitle">M4-调整评分调整项-01</td>
                        <td width="10%;" class="detailtitle">范围值</td>
                        <td width="10%;" class="detailtitle">调整事项</td>
                        <td width="10%;" class="detailtitle">调整分值</td>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="10%;">1</td>
                            <td width="60%;">隐瞒企业真实情况，提供的资料虚假或存在重大遗漏</td>
                            <td width="10%;">1-7</td>
                            <td width="10%;"><input type="text" v-model="form.jftz1"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz1"></td>
                        </tr>
                         <tr>
                            <td width="10%;">2</td>
                            <td width="60%;">存在虚增资产、虚报营业收入和利润的行为，财务数据的可信度低</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jftz2"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz2"></td>
                        </tr>
                         <tr>
                            <td width="10%;">3</td>
                            <td width="60%;">存在众多历史坏账、呆账，已发生或预期发生重大投融资损失</td>
                            <td width="10%;">1-13</td>
                            <td width="10%;"><input type="text" v-model="form.jftz3"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz3"></td>
                        </tr>
                         <tr>
                            <td width="10%;">4</td>
                            <td width="60%;">存在对外担保、行政处罚、未决司法诉讼等或有风险事项</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jftz4"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz4"></td>
                        </tr>
                         <tr>
                            <td width="10%;">5</td>
                            <td width="60%;">预期实施重大并购、资产重组、投资融资计划，或对企业产生不利影响</td>
                            <td width="10%;">1-7</td>
                            <td width="10%;"><input type="text" v-model="form.jftz5"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz5"></td>
                        </tr>
                         <tr>
                            <td width="10%;">6</td>
                            <td width="60%;">企业或其重要股东存在涉及失信的负面新闻报道和未决诉讼、仲裁等</td>
                            <td width="10%;">3-7</td>
                            <td width="10%;"><input type="text" v-model="form.jftz6"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz6"></td>
                        </tr>
                         <tr>
                            <td width="10%;">7</td>
                            <td width="60%;">企业或其重要股东出现银行贷款逾期或违约、担保逾期或代偿等情况</td>
                            <td width="10%;">7-17</td>
                            <td width="10%;"><input type="text" v-model="form.jftz7"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz7"></td>
                        </tr>
                         <tr>
                            <td width="10%;">8</td>
                            <td width="60%;">企业或其重要股东被纳入各类监管机构公示的受处罚名单或失信名单</td>
                            <td width="10%;">10-30</td>
                            <td width="10%;"><input type="text" v-model="form.jftz8"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz8"></td>
                        </tr>
                         <tr>
                            <td width="10%;">9</td>
                            <td width="60%;">其他减分调整因素、分析师和评审委员会的自由裁量权</td>
                            <td width="10%;">0-13</td>
                            <td width="10%;"><input type="text" v-model="form.jftz9"></td>
                            <td width="10%;"><input type="text" v-model="form.jffz9"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            <div class="credit-title" style="margin-top:0">
                <div>
                <div class="iconImg">
                    <img src="../../../assets/images/credit/credit-Model/经济@2x.png">
                </div>
                    <span>加分调整项</span>
                </div>
                <div>
                    <div class="credit-title-todetail" ></div>
                </div>
            </div>

            <div class="tableBox">
                <table class="re_search_detail_table">
                    <thead>
                    <tr>
                        <td width="70%;" colspan="2" class="detailtitle">M4-调整评分调整项-01</td>
                        <td width="10%;" class="detailtitle">范围值</td>
                        <td width="10%;" class="detailtitle">调整事项</td>
                        <td width="10%;" class="detailtitle">调整分值</td>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="10%;">1</td>
                            <td width="60%;">企业拥有实力较强的股东和信誉良好的机构投资者</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz1"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz1"></td>
                        </tr>
                        <tr>
                            <td width="10%;">2</td>
                            <td width="60%;">预期实施重大并购、资产重组、投资融资计划，或增强整体实力</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz2"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz2"></td>
                        </tr>
                        <tr>
                            <td width="10%;">3</td>
                            <td width="60%;">企业在专业领域贡献突出，获得政府或权威机构的奖励或表彰</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz3"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz3"></td>
                        </tr>
                        <tr>
                            <td width="10%;">4</td>
                            <td width="60%;">重视企业形象和品牌建设，积极履行社会责任</td>
                            <td width="10%;">0-3</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz4"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz4"></td>
                        </tr>
                        <tr>
                            <td width="10%;">5</td>
                            <td width="60%;">信用评级时间长且期间未产生实质性失信行为</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz5"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz5"></td>
                        </tr>
                        <tr>
                            <td width="10%;">6</td>
                            <td width="60%;">现有标准化评级方法和技术不尽完善，低估了企业实际信用状况</td>
                            <td width="10%;">0-7</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz6"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz6"></td>
                        </tr>
                        <tr>
                            <td width="10%;">7</td>
                            <td width="60%;">其他加分调整因素、分析师和评审委员会的自由裁量权</td>
                            <td width="10%;">0-13</td>
                            <td width="10%;"><input type="text" v-model="form.jjftz7"></td>
                            <td width="10%;"><input type="text" v-model="form.jjffz7"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="M4-point">
                <div class="M4-sum" style="width:300px;">
                    <div>减分调整合计</div> 
                    <div>{{jfNum}}</div>
                </div>

                <div class="M4-sum" style="width:300px;margin-left:25px;">
                    <div>加分调整合计</div>
                    <div>{{jjNum}}</div>
                </div>

                <div class="M4-sum" style="width:300px;margin-left:25px;">
                    <div>总分调整</div>
                    <div>{{jjNum-jfNum}}</div>
                </div>
            </div>
            
            <div class="M4-result">
                <div>
                    <img src="../../../assets/images/credit/credit-Model/组 54@2x.png"/>
                    <div class="title">最终确定信用评分</div>
                    <div class="Num">{{getZzNum(parseInt(anum) + (jjNum-jfNum))}}</div>
                </div>
                <div>
                    <img src="../../../assets/images/credit/credit-Model/组 55@2x.png"/>
                    <div class="title">最终认定信用级别</div>
                    <div class="Num">{{levelFUN(parseInt(anum) + (jjNum-jfNum))}}</div>
                </div>
            </div>

            <div class="M4-buttonList">
                <el-button type="primary" size="small" @click="save">保存</el-button>
                <el-button size="small" @click="routerTo('M2toM3')" icon="el-icon-arrow-left">上一页</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name:'M4',
  data(){
      return {
          anum:0,
          form:{ 
            text:'',

            jftz1:'',
            jftz2:'',
            jftz3:'',
            jftz4:'',
            jftz5:'',
            jftz6:'',
            jftz7:'',
            jftz8:'',
            jftz9:'',

            jffz1:'',
            jffz2:'',
            jffz3:'',
            jffz4:'',
            jffz5:'',
            jffz6:'',
            jffz7:'',
            jffz8:'',
            jffz9:'',

            jjftz1:'',
            jjftz2:'',
            jjftz3:'',
            jjftz4:'',
            jjftz5:'',
            jjftz6:'',
            jjftz7:'',

            jjffz1:'',
            jjffz2:'',
            jjffz3:'',
            jjffz4:'',
            jjffz5:'',
            jjffz6:'',
            jjffz7:'',
          },
          jfNum:0,
          jjNum:0,
          sumNum:0,
          zjNum:0
      }
  },
  mounted(){
      var entName = localStorage.getItem('dupontEntName')
      var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
      //console.log(entData.M4)
      if(entData.M4){
        var M4 = JSON.parse(entData.M4)
        this.form = M4
    }
    //   if(localStorage.getItem('M4')!=undefined){ 
    //       this.form = JSON.parse(localStorage.getItem('M4'))
    //   }
      this.getSum() 
 
      this.jfSum()
      this.jjSum()
      this.levelFUN(parseInt(this.anum/3) + (this.jjNum-this.jfNum))
  },
  watch:{
      $route(){
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
        if(entData.M4){
            var M4 = JSON.parse(entData.M4)
            this.form = M4
        }
        //   if(localStorage.getItem('M4')!=undefined){ 
        //       this.form = JSON.parse(localStorage.getItem('M4'))
        //   }
        this.getSum() 
    
        this.jfSum()
        this.jjSum()
        this.levelFUN(parseInt(this.anum/3) + (this.jjNum-this.jfNum))
      },
      form: {
        handler (val) {
          this.getSum()
          this.jfSum()
          this.jjSum()
          this.levelFUN(parseInt(this.anum/3) + (this.jjNum-this.jfNum))
        },
        // 这里是关键，代表递归监听 demo 的变化
        deep: true
      },
  },
  methods:{
    getSum(){
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

        var anum = 0
        anum += parseInt(entData.M1Point1)
        anum += parseInt(entData.m2sumNum)
        anum += parseInt(entData.M3point)

        var valueArr = [100,97,93,90,87,83,80,77,73,70,67,63,60,57,53,50,47,43,40,37,33,30,27,23,20]
        var value = parseInt(anum/3)

        var min = valueArr.find(item=>{return item <= value})

        this.anum = min
    },
    getZzNum(num){
        var valueArr = [100,97,93,90,87,83,80,77,73,70,67,63,60,57,53,50,47,43,40,37,33,30,27,23,20]
        var value =num>=100?100:num<=20?20:num

        var min = valueArr.find(item=>{return item <= value})
        return min>=100?100:min<=20?20:min
    },
    save(){
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

        this.$message({
            message: this.$store.state.saveText,
            type: 'success'
        });
        entData.M4 = JSON.stringify(this.form)
        localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))
    },
    jfSum(){
        this.jfNum = 0
        var i = 1
        while (i<=9) {
            this.jfNum += this.form[`jffz${i}`]?parseFloat(this.form[`jffz${i}`]):0
            i++
        }
    },
    jjSum(){
        this.jjNum = 0
        var i = 1
        while (i<=7) {
            this.jjNum += this.form[`jjffz${i}`]?parseFloat(this.form[`jjffz${i}`]):0
            i++  
        }
    },
    levelFUN(num){
        //console.log((num>100?100:num<20?20:num))
        var valueArr = [100,97,93,90,87,83,80,77,73,70,67,63,60,57,53,50,47,43,40,37,33,30,27,23,20]
        var value = '' 

        var min = valueArr.find(item=>{return item <= (num>100?100:num<20?20:num)})

        value = min>=100?100:min<=20?20:min

        var tableData= [ 
            {
            name: "AAA",
            value: ["10", "9.7"],
            },
            {
            name: "AA",
            value: ["9.3", "9.0", "8.7"],
            },
            {
            name: "A",
            value: ["8.3", "8.0", "7.7"],
            },
            {
            name: "BBB",
            value: ["7.3", "7.0", "6.7"],
            },
            {
            name: "BB",
            value: ["6.3", "6.0", "5.7"],
            },
            {
            name: "B",
            value: ["5.3", "5.0", "4.7"],
            },
            {
            name: "CCC",
            value: ["4.3", "4.0", "3.7"],
            },
            {
            name: "CC",
            value: ["3.3", "3.0", "2.7"],
            },
            {
            name: "C",
            value: ["2.3", "2.0"],
            },
        ]

        var level = ''
        for(let i in tableData){
            var a = tableData[i].value.find(item=>{
                return (item*10) == value
            })
            if(a!=undefined){
                level = tableData[i].name
            }
        }
        return level
    }, 
    routerTo(name){
        this.save()
        this.$router.push({name:name})
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../../../assets/css/table.css");
@media screen and (max-width: 1600px) {
  .M4 > div > .M4-result > div > .title{
    font-size:16px; 
  }
  .M4 > div > .M4-result > div > .Num{ 
    font-size: 40px;
  }
}
@media screen and (max-width: 1300px) {
  .M4 > div > .M4-result > div > .title{
    font-size:12px; 
  }
  .M4 > div > .M4-result > div > .Num{ 
    font-size: 30px;
  }
}
@media screen and (min-width: 1600px) {
  .M4 > div > .M4-result > div > .title{
    font-size:18px; 
  }
  .M4 > div > .M4-result > div > .Num{ 
    font-size: 60px;
  }
}
input{
    border:none;
    outline:none;
    text-align:center;
}
.M4{
    display: flex;
    justify-content: space-between;
    .tableBox{
        width: 100%;
        overflow-x: auto;
    }
    .M4-sum {
        display: flex;
        background: rgba(25, 190, 107, 0.5);
        border: 1px solid #dcdcdc;
        opacity: 1;
        > div {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        color: #30343d;
        &:first-child {
            width: 45%;
            height: 32px;
            border-right: 1px solid #ffffff;
        }
        &:last-child {
            width: 55%;
        }
        }
    }
    >div{
        width: 49%;
        >.M4-tip-title{
            width: 100%; 
            height: 40px;
            background: #2DB7F5;
            opacity: 1;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
        }
        >.M4-score{
            display: flex;
            margin-top: 10px;
            >.M4-score-textarea{
                width: 77%;
                height: 129px;
                border: 1px solid #C5C8CE;
                margin-right:10px;
                overflow: hidden;
                >textarea{
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border: none;
                    box-sizing:border-box;
                    padding:10px;
                }
            }
            >.M4-score-score{
                height: 129px;
                position: relative;
                >img{
                    height: 100%;
                }
                >.title{
                    width: 100%;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 25px;
                    color: #FFFFFF;
                    position: absolute;
                    top: 8px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                    text-align: center;
                }
                >.Num{
                    font-size: 60px;
                    font-weight: bold;
                    line-height: 25px;
                    color: #FFFFFF;
                    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    position: absolute;
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
        >.M4-result{
            display: flex;
            justify-content: space-between;
            >div{
                width: 50%;
                position: relative;
                >img{
                    width: 100%;
                }
                >.title{
                    width: 100%;
                    // font-size: 18px;
                    font-weight: bold;
                    line-height: 25px;
                    color: #FFFFFF;
                    position: absolute;
                    top:11px;
                    left: 19px;
                    z-index: 1;
                }
                >.Num{
                    // font-size: 60px;
                    font-weight: bold;
                    line-height: 25px;
                    color: #FFFFFF;
                    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    position: absolute;
                    top: 55%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
        >.M4-buttonList{
            display: flex;
            justify-content: flex-end;
        }
        >.M4-point{
            width: 100%;
            display: flex;
            justify-content: space-between;
            >div{
                width: 30%;
            }
        }
    }
}
</style>